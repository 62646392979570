var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',{staticClass:"statistic-wrap list_grid grid-cols-1 2xl:grid-cols-2"},[(_vm.fundingSourceStatistic)?_c('div',{staticClass:"statistic_item"},[_c('div',{staticClass:"total"},[_c('div',{staticClass:"label"},[_vm._v("Всего: ")]),_c('div',{staticClass:"value"},[_c('div',{staticClass:"value__count"},[_vm._v(_vm._s(_vm.fundingSourceStatistic.grand_total))]),_c('div',{staticClass:"value__measure"},[_vm._v("млн.тг")])])]),_c('div',{staticClass:"st-grid"},_vm._l((_vm.fundingSourceStatistic.statistic),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"label"},[_c('div',[_vm._v(_vm._s(item.source))]),_c('div',[_vm._v(_vm._s(item.value)+" млн.тг")])]),_c('div',{staticClass:"pb-wrap"},[_c('a-progress',{staticClass:"custom-progress",class:`trail-color-${index}`,attrs:{"percent":Number(item.percent),"show-info":false,"strokeWidth":21.45,"strokeColor":_vm.strokeColor(index)}}),_c('div',{staticClass:"progress-text",class:[
                                {white: index === 0 || index === 3},
                                {black: index === 1 || index === 2}
                            ]},[_vm._v(" "+_vm._s(item.percent)+" % ")])],1)])])}),0)]):_vm._e(),(_vm.categoryStatistic.length)?_c('div',{staticClass:"statistic_item"},[_c('div',{staticClass:"total"},[_c('div',{staticClass:"label"},[_vm._v("Всего: ")]),_c('div',{staticClass:"value"},[_c('div',{staticClass:"value__count"},[_vm._v(_vm._s(_vm.categoryStatistic[0].total_count))]),_c('div',{staticClass:"value__measure"},[_vm._v(_vm._s(_vm.projectWord(Number(_vm.categoryStatistic[0].total_count))))])])]),_c('div',{staticClass:"st-grid"},_vm._l((_vm.categoryStatistic),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"label"},[_c('div',[_vm._v(_vm._s(item.category.name))]),_c('div',[_vm._v(_vm._s(item.category_count))])]),_c('div',{staticClass:"pb-wrap"},[_c('a-progress',{staticClass:"custom-progress",class:`trail-color-${index}`,attrs:{"percent":Number(item.category_percent),"show-info":false,"strokeWidth":21.45,"strokeColor":_vm.strokeColor(index)}}),_c('div',{staticClass:"progress-text",class:[
                                {white: index === 0 || index === 3},
                                {black: index === 1 || index === 2},
                            ]},[_vm._v(" "+_vm._s(item.category_percent)+" % ")])],1)])])}),0)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }